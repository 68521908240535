<template>
<div class="row plain-element">
  <div v-if="!isLoggedIn()" class="dashboard-cards">
    <NoPermissionComponent/>
  </div>
  <div v-if="getUsername() && getSitePlanData()" class="dashboard">
    <div class="row row-cards">
      <div class="row text-start row-back">
        <a class="back-link" @click="hasHistory() ? $router.go(-1): $router.push('/')">
          <i class="fas fa-chevron-left"></i> Back To Plan
        </a>
      </div>
      <div class="card card-details card-kanban">
        <div class="row plain-element">
          <div class="col col-12 col-sm-12 col-md-3 col-lg-3">
            <div class="row plain-element">
              <div class="card card-status">
                <span><i class="fas fa-lightbulb" aria-hidden="true"></i> Opportunity</span>
              </div>
            </div>
            <div v-if="getSitePlanData()" class="row plain-element">
              <div v-for="opportunity in getSitePlanData().opportunity_plan_relations" :key="opportunity.id"
                   class="row plain-element">
                <div v-if="opportunity.opportunity__status === 'opportunity'" class="card project-card proposed"  @click="goToOpportunityView(opportunity.opportunity__id.toString())">
                  <div class="row plain-element project-card-top">
                    <div class="col col-12 col-sm-12 col-md-12 col-lg-12 plain-element text-start">
                      <h6> {{ truncatechars(opportunity.opportunity__description, 35) }}</h6>
                    </div>
                    <div class="col col-12 col-sm-12 col-md-12 col-lg-12 plain-element text-start">
                      <p> {{ opportunity.opportunity__opportunity_number }} </p>
                    </div>
                  </div>
                  <br>
                  <div class="row plain-element project-card-top">
                    <div class="col col-12 col-sm-12 col-md-12 col-lg-12 plain-element text-start">
                      <p>{{ opportunity.opportunity__seu__seu }} - {{ opportunity.opportunity__seu__area }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col col-12 col-sm-12 col-md-3 col-lg-3">
            <div class="row plain-element">
              <div class="card card-status">
                <span><i class="fa-solid fa-thumbs-up"></i> Approved</span>
              </div>
            </div>
            <div v-if="getSitePlanData()" class="row plain-element">
              <div v-for="opportunity in getSitePlanData().opportunity_plan_relations" :key="opportunity.id"
                   class="row plain-element">
                <div v-if="opportunity.opportunity__status === 'approved'" class="card project-card approved"  @click="goToOpportunityView(opportunity.opportunity__id.toString())">
                  <div class="row plain-element project-card-top">
                    <div class="col col-12 col-sm-12 col-md-12 col-lg-12 plain-element text-start">
                      <h6> {{ truncatechars(opportunity.opportunity__description, 35) }}</h6>
                    </div>
                    <div class="col col-12 col-sm-12 col-md-12 col-lg-12 plain-element text-start">
                      <p> {{ opportunity.opportunity__opportunity_number }} </p>
                    </div>
                  </div>
                  <br>
                  <div class="row plain-element project-card-top">
                    <div class="col col-12 col-sm-12 col-md-12 col-lg-12 plain-element text-start">
                      <p>{{ opportunity.opportunity__seu__seu }} - {{ opportunity.opportunity__seu__area }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col col-12 col-sm-12 col-md-3 col-lg-3">
            <div class="row plain-element">
              <div class="card card-status">
                <span><i class="fas fa-cog" aria-hidden="true"></i> In Progress</span>
              </div>
            </div>
            <div v-if="getSitePlanData()" class="row plain-element">
              <div v-for="opportunity in getSitePlanData().opportunity_plan_relations" :key="opportunity.id"
                   class="row plain-element">
                <div v-if="opportunity.opportunity__status === 'in progress'" class="card project-card in-progress"  @click="goToOpportunityView(opportunity.opportunity__id.toString())">
                  <div class="row plain-element project-card-top">
                    <div class="col col-12 col-sm-12 col-md-12 col-lg-12 plain-element text-start">
                      <h6> {{ truncatechars(opportunity.opportunity__description, 35) }}</h6>
                    </div>
                    <div class="col col-12 col-sm-12 col-md-12 col-lg-12 plain-element text-start">
                      <p> {{ opportunity.opportunity__opportunity_number }} </p>
                    </div>
                  </div>
                  <br>
                  <div class="row plain-element project-card-top">
                    <div class="col col-12 col-sm-12 col-md-12 col-lg-12 plain-element text-start">
                      <p>{{ opportunity.opportunity__seu__seu }} - {{ opportunity.opportunity__seu__area }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col col-12 col-sm-12 col-md-3 col-lg-3">
            <div class="row plain-element">
              <div class="card card-status">
                <span><i class="fa-solid fa-square-check"></i> Completed</span>
              </div>
            </div>
            <div v-if="getSitePlanData()" class="row plain-element">
              <div v-for="opportunity in getSitePlanData().opportunity_plan_relations" :key="opportunity.id"
                   class="row plain-element">
                <div v-if="opportunity.opportunity__status === 'completed'" class="card project-card completed"  @click="goToOpportunityView(opportunity.opportunity__id.toString())">
                  <div class="row plain-element project-card-top">
                    <div class="col col-12 col-sm-12 col-md-12 col-lg-12 plain-element text-start">
                      <h6> {{ truncatechars(opportunity.opportunity__description, 35) }}</h6>
                    </div>
                    <div class="col col-12 col-sm-12 col-md-12 col-lg-12 plain-element text-start">
                      <p> {{ opportunity.opportunity__opportunity_number }} </p>
                    </div>
                  </div>
                  <br>
                  <div class="row plain-element project-card-top">
                    <div class="col col-12 col-sm-12 col-md-12 col-lg-12 plain-element text-start">
                      <p>{{ opportunity.opportunity__seu__seu }} - {{ opportunity.opportunity__seu__area }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="error" v-if="getFormError()">
        <span id="formError">{{ getFormError() }}</span>
      </p>
      <p class="error" v-else>
      </p>

    </div>
  </div>
</div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue"


export default {
  name: "KanbanDashboard",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    NoPermissionComponent,
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapGetters(["getSitePlanData", "getUsername", "isLoggedIn","getFormError"]),
    ...mapActions(["fetchSitePlanData", "performSetFormError"]),
    getPlanDetails(id) {
      this.fetchSitePlanData({"id": id })
    },

    hasHistory () {
      return window.history?.length > 1
    },
    truncatechars (value, limit) {
      if (value) {
        if (value.length > limit) {
          value = value.substring(0, limit) + "...";
          }
          return value
      }
    },
    goToOpportunityView(id) {
      if (event.ctrlKey === true) {
        let routeData = this.$router.resolve({ name: 'opportunity-view', params: { id: id } });
        window.open(routeData.href, '_blank');
      } else {
//        this.$router.push({ name: 'opportunity-view', params: { id: id } });
        let routeData = this.$router.resolve({ name: 'opportunity-view', params: { id: id } });
        window.open(routeData.href, '_blank');
      }
    },
  },
  computed: {

  },
  created() {
    this.getPlanDetails(this.id);
    this.performSetFormError(null);
    document.title = "Kanban Dashboard";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.chosenId = to.params.id;
    this.chosenSiteId = to.params.site_id;
    this.getPlanDetails(this.chosenId);
    next();
  }

}
</script>
